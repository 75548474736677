import { timelineRoute } from 'src/routes/constants'

interface ILinkParams {
  route: string
  state: {
    periodItemId: string
  }
}

export const handleLink = (link: string): ILinkParams => {
  const [path, queryString] = link.split('?')
  if (path === timelineRoute) {
    const params = new URLSearchParams(queryString)
    const periodID = params.get('periodID')
    if (periodID) {
      return { route: timelineRoute, state: { periodItemId: periodID } }
    }
  }
  return null
}
