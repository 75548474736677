import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin: 16px 0 0;
  background: transparent;
  border: 1px solid ${props => props.theme.colors.timelinePurple60};
  border-radius: 6px;

  @media print {
    width: 100%;
    height: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 10px;
  gap: 3px;
`

const Title = styled.span`
  color: ${props => props.theme.colors.timelineIncome};
  font-weight: 500;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 130%;
`

const Text = styled.span`
  color: ${props => props.theme.colors.timelinePurple60};
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
`

export const dataAttrs = {
  container: () => 'info-block-view-element'
}

export interface IProps {
  title: string
  text: string
}

const InfoBlockView = React.memo((props: IProps) => {
  const { title, text } = props
  return (
    <Container data-testid={dataAttrs.container()}>
      <ContentContainer aria-hidden>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </ContentContainer>
    </Container>
  )
})

InfoBlockView.displayName = 'InfoBlockView'

export default InfoBlockView
