import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import {
  isOpenEndedPeriodMode,
  isPeriodWithoutEndDate
} from 'src/utils/periodUtils'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const isOpenEndedPeriod: boolean = isOpenEndedPeriodMode(input.period)
  const isOpenEndedPeriodWithoutEndDate: boolean =
    isOpenEndedPeriod && isPeriodWithoutEndDate(input.period)

  return {
    ...baseConfig,
    alerts: input.leave.metadata?.LTDwarning
      ? ['longTermDisabilityMedicalExtend']
      : baseConfig.alerts,
    datePickerComponent: MultiDatePicker,
    duration: isOpenEndedPeriodWithoutEndDate ? null : baseConfig.duration,
    periodInfoBlock: (t: any) =>
      isOpenEndedPeriodWithoutEndDate
        ? {
            title: t('timeline.periodInfoBlock.title'),
            text: t('timeline.periodInfoBlock.text')
          }
        : null,
    footerDatePickerTitle: isOpenEndedPeriod
      ? input.t('timeline.datePickerTitleByPeriod.changeEndDate')
      : baseConfig.footerDatePickerTitle,
    periodPickerDisableStartDate: isOpenEndedPeriod,
    datePickerFooterInfoMessage: (t: any) =>
      isOpenEndedPeriod
        ? t('timeline.datePickerAlerts.openEndedNotice.text')
        : null
  }
}
