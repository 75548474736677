import React from 'react'
import styled, { css } from 'styled-components'
import { Button, H1 } from 'src/UIKit'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'
import { useTranslation } from 'react-i18next'

interface IProps {
  onButtonClick: () => void
}

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
      margin: 0 auto;
    `}

  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  width: 100%;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 48px auto 40px;
        `
      : css`
          margin: 16px auto 20px;
        `}
`

const TextWrapper = styled.p`
  color: ${props => props.theme.colors.dark60};
  line-height: 150%;
  white-space: pre-wrap;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 736px;
          font-size: 16px;
        `
      : css`
          width: 100%;
          font-size: 14px;
        `}
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      align-items: flex-end;
      margin-bottom: 16px;
    `}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
        `}

  ${mobileButtonMaxWidthMixin};
`

export const dataAttrs = {
  title: () => 'blocked-journey-map-title'
}

const BlockedJourneyMapPage = React.memo((props: IProps) => {
  const { onButtonClick } = props
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('blockedJourneyMap.title')}</Title>
      <TextWrapper>{t('blockedJourneyMap.description')}</TextWrapper>
      <ButtonContainer>
        <ButtonWrapper onClick={onButtonClick}>
          {t('blockedJourneyMap.buttonTitle')}
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
})

BlockedJourneyMapPage.displayName = 'BlockedJourneyMapPage'

export default BlockedJourneyMapPage
