import React from 'react'
import {
  deniedClaimStatusRoute,
  extensionDeniedClaimStatusRoute,
  planChangeRoute
} from 'src/routes/constants'
import { Navigate } from 'react-router-dom'
import {
  hasNewTpaChanges,
  isDeniedClaimStatus,
  isExtensionDeniedClaimStatus
} from './leaveUtils'

interface IStateParams {
  employeeEmail: string
  periodStartDate: string
}

export const getNavigationComponent = (
  leave: ILeave,
  stateParams: IStateParams
) => {
  if (isDeniedClaimStatus(leave)) {
    return <Navigate to={deniedClaimStatusRoute} replace state={stateParams} />
  }
  if (isExtensionDeniedClaimStatus(leave)) {
    return (
      <Navigate
        to={extensionDeniedClaimStatusRoute}
        replace
        state={stateParams}
      />
    )
  }
  if (hasNewTpaChanges(leave)) {
    return <Navigate to={planChangeRoute} replace />
  }
  return null
}
