import moment, { Moment } from 'moment'
import { ICustomerConfig } from 'src/config/customers/config'
import {
  IHint,
  IMomentCurrentMinMax,
  ICountryCode,
  ITimelinePeriod,
  ITimelinePeriodBlock,
  Priority,
  TimelinePeriodType,
  IIconName
} from 'src/react-app-env'
import theme from 'src/theme'
import {
  isBabyArrivedLeave,
  isPublishedLeave
} from 'src/utils/leaveStatusUtils'
import { getPeriodConfig } from '../features/Timeline/periodsConfig'
import { DATE_FORMAT_WITH_DAY, isBlankDate } from './dateUtils'
import { isPeriodSynced } from './periodUtils'
import { isGoogle } from './userUtils'
import TimelineDiff from 'src/utils/timelineDiff'
import { ITimelineContext } from 'src/features/Timeline/Context'
import i18n from 'i18next'

const { colors } = theme()
export const BlockTypes = {
  Weekends: 'Weekends',
  PaidPublicHolidays: 'PaidPublicHolidays',
  PaidFamilyCare: 'PaidFamilyCare',
  PaidAnnual: 'PaidAnnual',
  MilitaryPayOnly: 'militaryPayOnly',
  SickTime: 'sickTime',
  Sick: 'Sick',
  PregnancyDisability: 'PregnancyDisability',
  Unused: 'Unused',
  Holidays: 'Holidays',
  AdditionalRosheSTD: 'Additional Roche STD',
  IntuitiveSTD: 'Intuitive STD',
  STD: 'STD',
  MakeupHolidays: 'Makeup Holidays',
  Vacation: 'Vacation',
  CasualLeave: 'CasualLeave',
  Sabbatical: 'Sabbatical',
  Sickness: 'Sickness',
  AdvanceVacation: 'AdvanceVacation',
  Bereavement: 'Bereavement',
  OtherNonWorkingTime: 'Other non-working time',
  UnpaidPersonal: 'Unpaid personal leave',
  UnpaidMilitary: 'Unpaid military leave'
}

export interface IDurationTextComponent {
  value: number
  duration: string
}

export interface ITimelinePeriodDatesRange {
  start: Moment
  end: Moment
}

export interface IDuration {
  components: IDurationTextComponent[]
  humanReadableString: string
  humanReadableFullString: string
  shortString: string
}

type ToastAction = 'remove' | 'merge' | 'available'

export interface IToastPeriod {
  item: ITimelinePeriod
  action: ToastAction
}

const toastExclusionPeriodTypes = [
  'AtWork',
  'OrdinaryMaternity',
  'PublicHolidays',
  'RampBack',
  'Unknown'
]

const babyArrivedTypes = ['Pregnancy', 'Maternity', 'MultiPregnancy']

const hidesIncomeTypes = [
  BlockTypes.MilitaryPayOnly,
  BlockTypes.Weekends,
  BlockTypes.OtherNonWorkingTime
]

const hidesPaymentsIncomeTypes = [
  BlockTypes.UnpaidPersonal,
  BlockTypes.UnpaidMilitary
]

const MAX_WEEKS_BEFORE_DUE_DATE = 6

let timelinePeriodBlockId = 1

const getTimelinePeriodBlockId = () => {
  if (timelinePeriodBlockId > 10000) {
    timelinePeriodBlockId = 1
  }
  return timelinePeriodBlockId++
}

const getMinDate = (date: string): Moment =>
  moment(date ? date : '1970-01-01T00:00:00Z').utc()

const getMaxDate = (date: string): Moment =>
  moment(date ? date : '2970-01-01T00:00:00Z').utc()

const getCurrentMinMaxDate = (date: ILeaveDate): IMomentCurrentMinMax => {
  if (!date) {
    return { current: null, min: null, max: null }
  }

  const current: Moment = moment(date.current).utc()
  const min: Moment = getMinDate(date.min).utc()
  const max: Moment = getMaxDate(date.max).utc()

  return { current, max, min }
}

const getTimelinePeriodItemDaysDuration = (tItem: any) => {
  if (!tItem) {
    return 0
  }
  if (tItem.startDate !== '0001-01-01T00:00:00Z') {
    return getDaysDuration(tItem.startDate, tItem.endDate)
  } else {
    return getDaysDuration(tItem.periodStart.current, tItem.periodEnd.current)
  }
}

const getDaysDuration = (startDate: string, endDate: string) => {
  const secondsInDay = 86400
  const start: Moment = moment(startDate).utc()
  const end: Moment = moment(endDate).utc()
  if (isBlankDate(start) || isBlankDate(end)) {
    return 0
  }
  let diff: number = end.diff(start, 'second')
  const extra: number = diff % secondsInDay
  if (extra !== 0) {
    diff += secondsInDay - extra
  }

  return Math.max(diff / secondsInDay, 0)
}

const getTimelinePeriodItemByType = (
  leave: ILeave,
  type: string
): ITimelinePeriodItem => {
  if (!leave || !leave.timeline) {
    return null
  }

  for (const item of leave.timeline) {
    if (item.type === type) {
      return item
    }
  }

  return null
}

const getTimelinePeriodItemByHash = (
  leave: ILeave,
  hash: string,
  type: string = null
): ITimelinePeriodItem => {
  if (!leave || !leave.timeline) {
    return null
  }

  for (const item of leave.timeline) {
    if (createPeriodHash(item, leave.timeline) === hash) {
      return item
    }
  }

  if (type) {
    return getTimelinePeriodItemByType(leave, type)
  }

  return null
}

const findTimelinePeriod = (
  timelinePeriods: ITimelinePeriod[],
  period: ITimelinePeriod
) =>
  timelinePeriods.find(
    p =>
      p.hash === period.hash ||
      (period.refID && p.type === period.type && p.refID === period.refID)
  )

const getNextTimelineItem = (
  leave: ILeave,
  item: ITimelinePeriodItem
): ITimelinePeriodItem => {
  if (!leave) {
    return null
  }
  const { timeline } = leave
  if (!timeline) {
    return null
  }
  const index: number = timeline.indexOf(item)
  if (index === timeline.length - 1) {
    return null
  }

  return timeline[index + 1]
}

const getPrevTimelineItem = (
  leave: ILeave,
  item: ITimelinePeriodItem
): ITimelinePeriodItem => {
  if (!leave) {
    return null
  }
  const { timeline } = leave
  if (!timeline) {
    return null
  }
  const index: number = timeline.indexOf(item)
  if (index === 0) {
    return null
  }

  return timeline[index - 1]
}

export const getPeriodDateRange = (period: ITimelinePeriod): string => {
  const currentStart = period.periodStart?.current || period.startDate
  const currentEnd = period.periodEnd?.current || period.endDate

  if (!currentStart || !currentEnd) {
    return ' - '
  }

  const start = moment(currentStart).utc().format(DATE_FORMAT_WITH_DAY)
  const end = isBlankDate(moment(currentEnd))
    ? 'TBD'
    : moment(currentEnd).utc().format(DATE_FORMAT_WITH_DAY)

  return `${start} - ${end}`
}

export const getPeriodDurationString = (
  leave: ILeave,
  period: ITimelinePeriod,
  t: any
): string => {
  const tItem: ITimelinePeriodItem = getTimelinePeriodItemByHash(
    leave,
    period.hash,
    period.type
  )

  if (
    ['UnpaidMedical', 'UnpaidPersonal'].includes(tItem.type) &&
    !tItem.blocks
  ) {
    return t(`timeline.notApplyDescription.${tItem.type}`)
  }

  return getPeriodDateRange(period)
}

const createPeriodHash = (
  period: ITimelinePeriodItem,
  timelinePeriods: ITimelinePeriodItem[]
): string => {
  const { type } = period
  let index = -1
  for (const p of timelinePeriods) {
    if (p.type === type) {
      index++

      if (p === period) {
        break
      }
    }
  }
  return `${type}_index:${index}`
}

// TODO: Temporary workaround to fix calendar issues with multiple addable periods of same type. Remove when addable period reference is fixed.
const createAddableItemHash = (
  period: ITimelinePeriodItem,
  timelinePeriods: ITimelinePeriodItem[]
): string => {
  if (period.appearance !== 'Addable') {
    return null
  }
  const { type } = period
  const index = timelinePeriods.filter(
    p => p.type === period.type && p.appearance !== 'Addable'
  ).length
  return `${type}_index:${index}`
}

const createGroupHash = (
  period: ITimelinePeriodItem,
  timelinePeriods: ITimelinePeriodItem[]
): string => {
  const { type } = period
  let index = -1
  for (const p of timelinePeriods) {
    if (p.type === type && p.appearance === period.appearance) {
      index++

      if (p === period) {
        break
      }
    }
  }
  return `${type}_${period.appearance}:${index}`
}

const getTimelinePeriods = (
  leave: ILeave,
  customer: string,
  customerConfig: ICustomerConfig,
  countryCode: ICountryCode,
  t: any
): ITimelinePeriod[] => {
  if (!leave) {
    return []
  }

  const timelineItems: ITimelinePeriodItem[] = [...leave.timeline]

  const periods: ITimelinePeriod[] = timelineItems.map(
    (tItem: ITimelinePeriodItem, index: number) => {
      const durationDays = getTimelinePeriodItemDaysDuration(tItem)

      return {
        hash: createPeriodHash(tItem, timelineItems),
        addableItemHash: createAddableItemHash(tItem, timelineItems),
        groupHash: createGroupHash(tItem, timelineItems),
        type: tItem.type as TimelinePeriodType,
        // TODO remove this when startDate is removed on back-end
        startDate:
          tItem.startDate !== '0001-01-01T00:00:00Z'
            ? moment(tItem.startDate)
            : getCurrentMinMaxDate(tItem.periodStart).current,
        // TODO remove this when endDate is removed on back-end
        endDate:
          tItem.endDate !== '0001-01-01T00:00:00Z'
            ? moment(tItem.endDate)
            : getCurrentMinMaxDate(tItem.periodEnd).current,
        periodStart: getCurrentMinMaxDate(tItem.periodStart),
        periodEnd: getCurrentMinMaxDate(tItem.periodEnd),
        appearance: tItem.appearance,
        mode: tItem.mode,
        durationDays,
        duration: getPeriodDurationTextComponents(durationDays, t),
        daysOnLeave: tItem.daysOnLeave,
        claimStatus: tItem.claimStatus,
        convertibleToType: tItem.convertibleToType as TimelinePeriodType[],
        isDirty: tItem.isDirty,
        schedule: tItem.schedule,
        blocks: getBlocksForTimelinePeriod(
          leave,
          tItem,
          countryCode,
          customer,
          customerConfig,
          t
        ),
        index,
        id: tItem.itemID,
        refID: tItem.refItemID,
        isRemovable: tItem.isRemovable,
        isAdjusted: tItem.isAdjusted,
        details: tItem.details,
        rates: tItem.rates,
        itemID: tItem.itemID,
        hasDynamicItemID:
          customerConfig.leave.timeline.hasDynamicItemID(countryCode),
        disabledDates: tItem.disabledDates
      }
    }
  )

  assignParents(periods)

  periods.forEach((period: ITimelinePeriod) => {
    period.durationString = getPeriodDurationString(leave, period, t)
    period.timelineConfig = getPeriodConfig({
      period,
      t,
      customer,
      countryCode,
      leave,
      periods
    })
    period.hints = getHintsForPeriod(leave, period, t)
  })

  return periods
}

const assignParents = (periods: ITimelinePeriod[]) => {
  let parentPeriod = null
  periods.forEach((period: ITimelinePeriod) => {
    if (period.appearance !== 'Addable') {
      parentPeriod = period
      return
    }

    if (parentPeriod?.type === 'AtWork' || parentPeriod?.type === 'RampBack') {
      period.parent = parentPeriod
    }
  })
}

const getHintPostfix = (leave: ILeave, period: ITimelinePeriod): string => {
  if (period.timelineConfig?.detailsHintPostfix) {
    return period.timelineConfig.detailsHintPostfix
  }
  return leave.note ? 'NonQualifying' : ''
}

const getHintsForPeriod = (
  leave: ILeave,
  period: ITimelinePeriod,
  t: any
): IHint[] => {
  const postfix: string = getHintPostfix(leave, period)
  let type: string = period.timelineConfig?.detailsType || period.type
  if (leave.type === 'Military' && period.type === 'Recovery') {
    type = 'MilitaryRecovery'
  }

  const key = `timelineHints.periods.${type}.faqs${postfix}`

  return i18n.exists(key)
    ? Array.from(
        t(key, {
          returnObjects: true,
          ...leave.metadata
        })
      )
    : []
}

export const parseIncomeValue = (incomeValue: any): string => {
  const result: number = parseFloat(incomeValue.toString())
  if (isNaN(result)) {
    return '0'
  }

  if (result < 0) {
    return '0'
  }

  if (Math.round(result) === result) {
    return result.toString()
  }

  if (Math.round(result * 10) === result * 10) {
    return result.toFixed(1)
  }

  return result.toFixed(2)
}

export const completeIncomeValue = (
  incomeValue: string,
  incomeType: string,
  t: any,
  customer: string = null
): string => {
  if (incomeValue === '0' && incomeType === 'payment') {
    return t('common.no').toUpperCase()
  }
  if (incomeValue === '0' && incomeType === 'partial') {
    return t('common.partial').toUpperCase()
  }

  if (incomeType === 'euro') {
    return `€${incomeValue}`
  }

  if (incomeType === 'usd') {
    return `$${incomeValue}`
  }

  if (incomeType === 'usd approx') {
    return `~$${incomeValue}`
  }

  if (isGoogle(customer) && incomeType !== 'regular pay') {
    return `~${incomeValue}%`
  }

  return `${incomeValue}%`
}

const getBlocksForTimelinePeriod = (
  leave: ILeave,
  tItem: ITimelinePeriodItem,
  countryCode: ICountryCode,
  customer: string,
  customerConfig: ICustomerConfig,
  t: any
): ITimelinePeriodBlock[] => {
  const result: ITimelinePeriodBlock[] = []
  const { blocks } = tItem
  if (!blocks) {
    return result
  }

  const getIsUnused = (b: IPeriodBlock): boolean =>
    b.type === 'Unused' || b.type === 'unused'

  for (let i = 0; i < blocks.length; i++) {
    const block: IPeriodBlock = blocks[i]
    const isUnused: boolean = getIsUnused(block)
    const isPartial: boolean = block.incomeType === 'partial'
    const isApproximatePayment: boolean = block.incomeType === 'usd approx'
    const { unpaidDays } = block
    const isFamilyUnpaidEdgeCase: boolean =
      unpaidDays > 0 && block.type === BlockTypes.PaidFamilyCare
    const incomeValue: string = completeIncomeValue(
      parseIncomeValue(block.incomeValue),
      block.incomeType,
      t,
      customer
    )
    const incomeNote: string = t(
      `timeline.incomeNoteByType.${block.incomeType}`
    )

    const durationDays: number = block.duration
    const durationComponents: IDuration = getPeriodDurationTextComponents(
      durationDays,
      t
    )

    let duration: string

    switch (block.durationType) {
      case 'Workday':
        duration = t('common.workday', { count: durationDays })
        break
      case 'Weekday':
        duration = t('common.weekday', { count: durationDays })
        break
      default:
        duration = durationComponents.humanReadableString
    }

    const jobProtected: boolean = block.jobProtected === 'Yes'
    const jobProtectedText: string =
      block.jobProtected !== 'None' &&
      t(
        `timeline.${
          block.jobProtected === 'Yes' ? 'jobProtected' : 'jobUnprotected'
        }`
      )

    const hidesIncomeSection: boolean =
      isFamilyUnpaidEdgeCase ||
      customerConfig.leave.timeline.hideBlockIncomeSection(
        leave,
        countryCode,
        block
      ) ||
      hidesIncomeTypes.includes(block.type) ||
      (hidesPaymentsIncomeTypes.includes(block.type) &&
        block.incomeValue === 0 &&
        block.incomeType === 'payment')

    let type: string = isFamilyUnpaidEdgeCase
      ? `${t('timeline.titleByTimelineItemType.familyCarePaidDays', {
          count: tItem.daysOnLeave - unpaidDays,
          type: t(`timeline.incomeNoteByType.${block.incomeType}`)
        })}&nbsp;
        ${t('timeline.titleByTimelineItemType.familyCareUnpaidDays', {
          count: unpaidDays
        })}`
      : t([
          `timeline.titleByTimelineItemType.${block.type}_${tItem.type}`,
          `timeline.titleByTimelineItemType.${block.type}`
        ])

    if (block.type === BlockTypes.PaidPublicHolidays) {
      const periods = leave.timeline
      const index: number = periods.indexOf(tItem)
      if (index > 0) {
        const context: string = periods[index - 1].type
        type = t('timeline.publicHolidaysDescription', { context })
      }
    }

    if (block.programNames?.length > 0 && !isUnused) {
      type = block.programNames.join(t('timeline.blockProgramSeparator'))
    }

    const requiredActions = block.requirements
    const isWeekend = block.type === 'Weekends'
    const isRampBack = block.type === 'Ramp Back Time'
    const startDate = block.startDate ? moment(block.startDate).utc() : null
    const endDate = block.endDate ? moment(block.endDate).utc() : null

    result.push({
      id: getTimelinePeriodBlockId(),
      isApproximatePayment,
      isUnused,
      isPartial,
      incomeValue: `${incomeValue}`,
      incomeNote,
      isWeekend,
      isRampBack,
      type,
      duration,
      durationFullString: durationComponents.humanReadableFullString,
      durationDays,
      jobProtected,
      jobProtectedText,
      requiredActions,
      hidesIncomeSection,
      activeDays: block.activeDays,
      activeDaysWeekOne: block.activeDaysWeekOne,
      activeDaysWeekTwo: block.activeDaysWeekTwo,
      startDate,
      endDate,
      unpaidDays,
      key: `${tItem.type}.${i}`
    })
  }

  return result
}

const getLeaveStartDate = (leave: ILeave): IMomentCurrentMinMax => {
  const {
    dates: { leaveStart }
  } = leave
  return getCurrentMinMaxDate(leaveStart)
}

const getDueDate = (leave: ILeave): IMomentCurrentMinMax => {
  const current: Moment = moment(leave.dueDate).utc()
  return { current, min: getMinDate(null), max: getMaxDate(null) }
}

const getLeaveGradualReturnStartDate = (
  leave: ILeave
): IMomentCurrentMinMax => {
  const {
    dates: { gradualReturnStartDate }
  } = leave
  const result: IMomentCurrentMinMax = getCurrentMinMaxDate(
    gradualReturnStartDate
  )
  if (!gradualReturnStartDate) {
    result.current = null
  }

  return result
}

const getActiveDutyEndDate = (leave: ILeave): IMomentCurrentMinMax => {
  const {
    dates: { activeDutyEndDate }
  } = leave
  const result: IMomentCurrentMinMax = getCurrentMinMaxDate(activeDutyEndDate)
  if (!activeDutyEndDate) {
    result.current = null
  }

  return result
}

const getBirthDate = (leave: ILeave): IMomentCurrentMinMax => {
  const current: Moment = moment(leave.dueDate).utc()
  return { current, min: current, max: current }
}

const getDisabilityEndDate = (leave: ILeave): IMomentCurrentMinMax => {
  const {
    dates: { disabilityEnd }
  } = leave
  return getCurrentMinMaxDate(disabilityEnd)
}

const getLeaveEndDate = (leave: ILeave): IMomentCurrentMinMax => {
  const {
    dates: { leaveEnd }
  } = leave
  return getCurrentMinMaxDate(leaveEnd)
}

const getFromStartToReturnDuration = (leave: ILeave, unitOfTime: any) => {
  const s: string = leave.dates.leaveStart.current
  // family care leave may not have end date
  const e: string = leave.dates.leaveEnd?.current || s
  const start: Moment = moment(s).utc()
  const end: Moment = moment(e).utc()
  return Math.max(end.diff(start, unitOfTime), 1)
}

const getTotalLeaveDuration = (leave: ILeave) => {
  const { timeline } = leave
  let result = 0

  timeline
    .filter((item: ITimelinePeriodItem) => item.appearance !== 'Addable')
    .filter((item: ITimelinePeriodItem) => item.type !== 'RampBack')
    .filter((item: ITimelinePeriodItem) => item.type !== 'AtWork')
    .forEach((item: ITimelinePeriodItem) => {
      result += getTimelinePeriodItemDaysDuration(item)
    })

  return result
}

const getPeriodDurationTextComponents = (
  days: number,
  t: (key: string, extras: any) => any,
  showAvailable?: boolean,
  markValueBold?: boolean
): IDuration => {
  const components: IDurationTextComponent[] = []
  const years: number = Math.max(Math.floor(days / 365), 0)

  if (years > 0) {
    const yearText = showAvailable
      ? 'common.durations.availableYear'
      : 'common.durations.year'
    components.push({
      value: years,
      duration: t(yearText, { count: years })
    })
    days -= years * 365
  }

  const weeks: number = Math.max(Math.floor(days / 7), 0)

  if (weeks > 0) {
    const weekText =
      showAvailable && years === 0
        ? 'common.durations.availableWeek'
        : 'common.durations.week'
    components.push({
      value: weeks,
      duration: t(weekText, { count: weeks })
    })
  }

  const componentDays: number = Math.max(days - weeks * 7, 0)
  if (components.length === 0 || componentDays !== 0) {
    const dayText =
      showAvailable && years === 0 && weeks === 0
        ? 'common.durations.availableDay'
        : 'common.durations.day'
    components.push({
      value: componentDays,
      duration: t(dayText, { count: componentDays })
    })
  }

  const isShort: boolean = components.length > 1
  let humanReadableString = ''
  let humanReadableFullString = ''
  let shortString = ''
  components.forEach((comp: IDurationTextComponent, index: number) => {
    humanReadableFullString += markValueBold
      ? `**${comp.value}** ${comp.duration}`
      : `${comp.value} ${comp.duration}`
    if (isShort) {
      humanReadableString += `${comp.value}${comp.duration.charAt(0)}`
    } else {
      humanReadableString += `${comp.value} ${comp.duration}`
    }

    shortString += `${comp.value}${comp.duration.charAt(0)}`

    if (index !== components.length - 1) {
      humanReadableFullString += ` `
      humanReadableString += ' '
      shortString += ' '
    }
  })

  return {
    components,
    humanReadableString,
    humanReadableFullString,
    shortString
  }
}

const getFilterPropsByJourneyMapItemType = (p: Priority) => {
  let color: string

  switch (p) {
    case 'Critical':
      color = colors.specialCritical
      break
    case 'High':
      color = colors.specialHigh
      break
    case 'Medium':
      color = colors.specialMedium
      break
    case 'Low':
      color = colors.specialLow
      break

    default:
      color = '#fff'
  }
  return { color }
}

const getIconNameByJourneyMapItemType = (p: Priority) => {
  let iconName: IIconName

  switch (p) {
    case 'Critical':
      iconName = 'chevron_double_up'
      break
    case 'High':
      iconName = 'chevron_up'
      break
    case 'Medium':
      iconName = 'chevron_down'
      break
    case 'Low':
      iconName = 'dot_small'
      break

    default:
      iconName = null
  }
  return iconName
}

const miscarriageSupported = (leave: ILeave): boolean =>
  leave.metadata?.miscarriageSupported

const shouldShowBabyArrivedButton = (leave: ILeave): boolean => {
  const { type, status, dueDate, tpa, isDirty } = leave

  const isValidType = babyArrivedTypes.includes(type)
  const isSyncingOrDirty = tpa?.syncing || isDirty
  const isWithinWeeksThreshold =
    moment(dueDate).diff(moment().subtract(1, 'day'), 'weeks') <
    MAX_WEEKS_BEFORE_DUE_DATE

  if (!isValidType || isSyncingOrDirty) {
    return false
  }

  return isPublishedLeave(status) && isWithinWeeksThreshold
}

const shouldShowSubtypeSelector = (
  leave: ILeave,
  subtypes: ILeaveSubType[]
): boolean => {
  const { subtype, tpa, type, timeline } = leave
  const recoveryPeriod = timeline?.find(tItem => tItem.type === 'Recovery')
  if (
    subtype?.length === 0 ||
    subtypes?.length === 0 ||
    leave.metadata?.disableSubtypeSelector === true
  ) {
    return false
  }

  if (tpa) {
    if (recoveryPeriod && tpa.syncing) {
      return !isPeriodSynced(recoveryPeriod)
    }
    return !tpa.syncing
  }

  return type !== 'Miscarriage'
}

const getDueDateLabelKey = (leave: any): string => {
  const { type, status } = leave
  if (type === 'Adoption' || type === 'Foster') {
    return 'common.placementDate'
  }

  if (type === 'Pregnancy' && isBabyArrivedLeave(status)) {
    return 'common.birthDate'
  }

  if (type === 'Miscarriage') {
    return 'common.pregnancyLoss'
  }

  return 'common.dueDate'
}

const isCertificationRequired = (leave: ILeave, periodType: string): boolean =>
  leave.timeline.some(
    tItem =>
      tItem.type === periodType &&
      tItem.details?.requirements?.includes(
        'Certification' as IPeriodBlockRequirement
      )
  )

const rbtToastMessage = (diff: TimelineDiff): string => {
  const changedBB = diff.changed(({ to }) => to.type === 'BabyBonding')
  const changedRBT = diff.changed(({ to }) => to.type === 'RampBack')

  const addedRBT = diff
    .added(({ to }) => to.type === 'RampBack')
    .filter(({ to }) => to.appearance !== 'Addable')

  const removedRBT = diff
    .removed(({ from }) => from.type === 'RampBack')
    .filter(({ from }) => from.appearance !== 'Addable')

  const isBBDecreased =
    changedBB.filter(({ from, to }) => to.durationDays < from.durationDays)
      .length > 0

  const isBBIncreased =
    changedBB.filter(({ from, to }) => to.durationDays > from.durationDays)
      .length > 0

  if (isBBDecreased && removedRBT.length > 0) {
    return 'removedBecauseOfLeaveLength'
  } else if (isBBIncreased && addedRBT.length > 0) {
    return 'addedBecauseOfLeaveLength'
  } else if (removedRBT.length > 0) {
    return 'removed'
  }

  const isRBTAvailable = changedRBT.find(
    item =>
      item.to.appearance === 'Addable' &&
      item.from.mode === 'ReadOnly' &&
      item.to.mode === 'Default'
  )

  if (isBBIncreased && isRBTAvailable) {
    return 'availableBecauseOfLeaveLength'
  }

  return null
}

export const isFirstPeriodWithType = (hash: string): boolean =>
  hash && hash.indexOf('index:0') !== -1

export const getPeriodI18Context = (period: ITimelinePeriod, leave: ILeave) => {
  if (!period) {
    return null
  }

  return `${leave.type}_${period.type}`
}

export const hasNewTpaChanges = (leave: ILeave): boolean =>
  leave?.tpa?.changes?.status === 'New'

export const hasSeenTpaChanges = (leave: ILeave): boolean =>
  leave?.tpa?.changes?.status === 'Seen'

const filterItemsForCheck = (periods: ITimelinePeriod[]): ITimelinePeriod[] =>
  periods.filter(
    period =>
      (period.itemID || period.appearance === 'Period') &&
      !toastExclusionPeriodTypes.includes(period.type)
  )

const isCreatePeriodRequest = (changes: any): boolean => {
  if (!changes || !changes.period) {
    return false
  }

  const { period } = changes

  return !period.id && period.type && period.duration > 0
}

const getMergedCreatedPeriods = (diff: TimelineDiff, changes: any) => {
  if (isCreatePeriodRequest(changes)) {
    const created = diff.added(item => item.to.type === changes.period.type)
    const increased = diff.changed(
      item =>
        item.to.type === changes.period.type &&
        item.to.durationDays > item.from.durationDays
    )[0]

    const alreadyMerged = diff.merged(
      item => item.to.type === changes.period.type
    )

    if (created.length === 0 && increased && alreadyMerged.length === 0) {
      return [increased]
    }
  }
  return []
}

const getBecomeAvailablePeriods = (
  prev: ITimelinePeriod[],
  next: ITimelinePeriod[]
) => {
  const prevFiltered = prev.filter(
    item => !toastExclusionPeriodTypes.includes(item.type)
  )

  const nextFiltered = next.filter(
    item => !toastExclusionPeriodTypes.includes(item.type)
  )
  const diff = new TimelineDiff(prevFiltered, nextFiltered)

  return diff
    .changed(
      item =>
        item.to.appearance === 'Addable' &&
        item.from.mode === 'ReadOnly' &&
        item.to.mode === 'Default'
    )
    .map(item => ({ item: item.from, action: 'available' }))
}

export const getPeriodTypesForToast = (
  prev: ITimelinePeriod[],
  next: ITimelinePeriod[],
  latestRequestChanges: any
) => {
  if (!prev || !next) {
    return [] as IToastPeriod[]
  }

  const prevItems = filterItemsForCheck(prev)
  const nextItems = filterItemsForCheck(next)

  const availablePeriods = getBecomeAvailablePeriods(prev, next)

  if (prevItems.length < nextItems.length) {
    if (availablePeriods.length) {
      return availablePeriods as IToastPeriod[]
    }
    return [] as IToastPeriod[]
  }

  const diff = new TimelineDiff(prevItems, nextItems)
  const removed = diff
    .removed(
      item =>
        item.from.appearance === 'Standard' || item.from.appearance === 'Period'
    )
    .map(item => ({ item: item.from, action: 'remove' }))

  const merged = diff
    .merged()
    .map(item => ({ item: item.from, action: 'merge' }))

  getMergedCreatedPeriods(diff, latestRequestChanges).forEach(item => {
    merged.push({ item: item.to, action: 'merge' })
  })

  return [...removed, ...merged, ...availablePeriods] as IToastPeriod[]
}

const isPreOrdinaryMaternity = (period: any): boolean =>
  period?.hash === 'OrdinaryMaternity_index:0'

const isPostOrdinaryMaternity = (period: any): boolean =>
  period?.hash === 'OrdinaryMaternity_index:1'

const hasAddableBabyBonding = (leave: ILeave) =>
  leave.timeline.some(
    (tItem: ITimelinePeriodItem) =>
      tItem.type === 'BabyBonding' && tItem.appearance === 'Addable'
  )

const isFamilyLeaveNotIncludingPersonal = (leave: ILeave) =>
  leave.type === 'Family' &&
  !leave?.timeline?.some(
    (tItem: ITimelinePeriodItem) =>
      tItem.type === 'Personal' && tItem.appearance === 'Standard'
  )

const getDurationItem = (durationType: IDurationType): string => {
  switch (durationType) {
    case 'Day':
      return 'day'
    case 'Workday':
      return 'workday'
    case 'Weekday':
      return 'weekday'
    default:
      return 'day'
  }
}

const openDatePickerForPreference = (
  {
    timelinePeriods,
    timelinePreference,
    setCurrentDatePickerViewPeriod
  }: ITimelineContext,
  preference: string
) => {
  if (timelinePreference !== preference) {
    return
  }

  const periodToOpen = timelinePeriods.find(
    period => period.type === 'BabyBonding' && period.appearance === 'Addable'
  )
  if (periodToOpen) {
    setCurrentDatePickerViewPeriod(periodToOpen)
  }
}

const getLeaveTypeDisabilityGroupName = (leaveType: ILeaveType): string =>
  leaveType === 'Pregnancy' || leaveType === 'Medical'
    ? 'disability'
    : 'nonDisability'

const isSyncingLeaveAndClaimExists = (leave: ILeave): boolean => {
  if (!leave?.tpa) {
    return false
  }

  return leave.tpa.syncing && !!leave.tpa.syncedAt
}

const isDeniedClaimStatus = (leave: ILeave): boolean =>
  leave?.tpa?.tpaLeaveStatus === 'Denied'

const isExtensionDeniedClaimStatus = (leave: ILeave): boolean =>
  leave?.tpa?.tpaLeaveStatus === 'ExtensionDenied'

const isPregnancyLeaveWithoutBabyArrived = (leave: ILeave) =>
  leave?.type === 'Pregnancy' && !isBabyArrivedLeave(leave?.status)

export {
  findTimelinePeriod,
  getActiveDutyEndDate,
  getBirthDate,
  getDisabilityEndDate,
  getDueDate,
  getDueDateLabelKey,
  getDurationItem,
  getFilterPropsByJourneyMapItemType,
  getFromStartToReturnDuration,
  getIconNameByJourneyMapItemType,
  getLeaveEndDate,
  getLeaveGradualReturnStartDate,
  getLeaveStartDate,
  getPeriodDurationTextComponents,
  getTimelinePeriodItemByType,
  getTimelinePeriodItemDaysDuration,
  getTimelinePeriods,
  getTotalLeaveDuration,
  hasAddableBabyBonding,
  isCertificationRequired,
  isDeniedClaimStatus,
  isExtensionDeniedClaimStatus,
  isFamilyLeaveNotIncludingPersonal,
  isPostOrdinaryMaternity,
  isPreOrdinaryMaternity,
  isPregnancyLeaveWithoutBabyArrived,
  rbtToastMessage,
  miscarriageSupported,
  shouldShowBabyArrivedButton,
  shouldShowSubtypeSelector,
  openDatePickerForPreference,
  isSyncingLeaveAndClaimExists,
  // below for tests only
  getNextTimelineItem,
  getPrevTimelineItem,
  getLeaveTypeDisabilityGroupName
}
