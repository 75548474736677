import * as Sentry from '@sentry/react'

const ALLOWED_URLS = [/^https:\/\/.*\.getveer\.com/]
const IGNORE_ERRORS = [
  /^Failed to register a ServiceWorker for scope/,
  /^Non-Error promise rejection captured/,
  /^Response not successful: Received status code 401/
]
const WHITE_LIST = ['id', 'countryCode', 'countryState', 'timezone']
const IGNORE_STATUS_CODES = [401]

export const beforeSendCleanup = (event: any) => {
  if (event.extra && IGNORE_STATUS_CODES.includes(event.extra.status)) {
    return null
  }

  if (event.user) {
    event.user = filterAttributes(event.user)
  }
  if (event?.contexts?.user) {
    event.contexts.user = filterAttributes(event.contexts.user)
  }
  if (event?.extra?.user) {
    event.extra.user = filterAttributes(event.extra.user)
  }

  return event
}

const filterAttributes = (obj: any) => {
  const newObj: any = {}
  for (const key in obj) {
    if (WHITE_LIST.includes(key)) {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

export const captureResponseError = (response: any) => {
  Sentry.captureMessage(`Response error: ${response.url}`, {
    extra: {
      status: response.status,
      statusText: response.statusText,
      route: window.location.href
    }
  })
}

export const captureException = (error: any) => {
  Sentry.captureException(error)
}

export const initSentry = () => {
  const config = window.APP_CONFIG

  if (!config?.SENTRY.DSN) {
    console.warn('No Sentry DSN provided, skipping Sentry initialization')
    return
  }

  Sentry.init({
    dsn: config.SENTRY.DSN,
    release: config.SENTRY.TAG,
    environment: config.SENTRY.ENV,
    integrations: [Sentry.browserTracingIntegration()],
    beforeSend: event => beforeSendCleanup(event),
    allowUrls: ALLOWED_URLS,
    ignoreErrors: IGNORE_ERRORS,
    tracesSampleRate: 0, // Disables performance monitoring
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}
